//
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop></Mtop>

    <Mbottom></Mbottom>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mSearch',
  data() {
    return {};
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
  },
  mounted() {
    that.YS.closeallWin(); //关闭所有弹窗
  },
  methods: {},
};
</script>
<style lang="scss" scope></style>
